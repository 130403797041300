import React from "react";
import { useNavigate } from "react-router-dom";

import "./components.css";

export default function FancyButton({ label, linkTo, black, external }) {
	const navigate = useNavigate();

	return (
		<button
			className="fancy"
			onClick={() => {
				external ? window.open(linkTo, "_blank") : navigate(linkTo);
			}}
		>
			<div className={`content ${black ? "black-btn" : "white-btn"}`}>
				{label}
			</div>
		</button>
	);
}
