import React, { useContext } from "react";
import FancyButton from "../components/FancyButton";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import NotificationContext from "../context/NotificationContext";

export default function Footer() {
	const { ref, inView } = useInView({
		threshold: 0.1,
	});
	const showSnackbar = useContext(NotificationContext);

	return (
		<footer>
			<motion.div
				className="footer"
				ref={ref}
				initial={{ opacity: 0, y: 200 }}
				animate={{
					opacity: inView ? 1 : 0,
					y: inView ? 0 : 200,
				}}
				transition={{ ease: "easeOut", duration: 0.6 }}
			>
				<div className="container">
					<h2>Let's Work together</h2>
					<div className="content">
						<div className="fill" />
						<div className="content-inner">
							<p className="xs">
								I’m currently available for new work. If you’re
								looking for a product designer, let’s talk about
								your next big thing!
							</p>
							<FancyButton
								label="Say hi"
								linkTo="mailto:munafhassan23@gmail.com"
								black
								external
								onHoverLabel={"munafhassan23@gmail.com"}
							/>
						</div>
					</div>
				</div>

				<div className="footer-bottom">
					<p className="ending-footer">
						Built with <i>you</i> in mind.
					</p>
					<a
						className="email-footer"
						onClick={() => {
							// copy email
							navigator.clipboard.writeText(
								"munafhassan23@gmail.com"
							);
							showSnackbar("Email copied!");
						}}
					>
						mmunaf.designer@gmail.com
					</a>
				</div>
			</motion.div>
		</footer>
	);
}
