import React from "react";

import {
	Paragraph,
	ProjectBody,
	ProjectHeader,
	ProjectImage,
	UnorderedList,
} from "./ProjectComponents";

import Img1 from "../../assets/images/travelbuddy/Wallpaper.png";
import Img2 from "../../assets/images/travelbuddy/user-flow.png";
//import Img3 from "../../assets/images/travelbuddy/Wallpaper-0.png";
import Img4 from "../../assets/images/travelbuddy/Wallpaper-1.png";
import Img5 from "../../assets/images/travelbuddy/Wallpaper-2.png";
import Img6 from "../../assets/images/travelbuddy/Wallpaper-3.png";
import Img7 from "../../assets/images/travelbuddy/Wallpaper-4.png";
import Img8 from "../../assets/images/travelbuddy/Wallpaper-5.png";
import Img9 from "../../assets/images/travelbuddy/Wallpaper-6.png";

import "./project-styles.css";

export default function TravelBuddy() {
	return (
		<main className="project-page">
			<ProjectHeader
				title="TravelBuddy"
				subheading={
					"Reimagining carpooling in Pakistan, offering flexible travel choices, and prioritizing safety with complete transparency for a seamless commute experience."
				}
				year="2022"
				role="Product Designer"
			/>
			<div className="project-content">
				<ProjectImage src={Img1} />

				<ProjectBody
					title="The Challange"
					children={[
						<Paragraph
							body={
								<p>
									Imagine this - every day, tangled in the web
									of traffic jams, watching the fuel gauge dip
									and your wallet thinning. In Pakistan,
									commuting was becoming less of a routine and
									more of a battle.
								</p>
							}
						/>,
					]}
				/>
				<ProjectBody
					title="Process"
					children={[
						<Paragraph
							body={
								<p>
									Diving into research, I found existing apps
									lacking - one local option, completely
									abandoned due to security issues, and a
									popular European app <b>(BlaBlaCar)</b> that
									didn't operate in Pakistan.
								</p>
							}
						/>,
						<Paragraph
							body={
								<p>
									Turning to conversations - with university
									friends, online forums, and social circles -
									helped me understand the problem better. It
									all came down to one thing: Security. In
									Pakistan, carpooling with a stranger raised
									concerns.
								</p>
							}
						/>,
						<Paragraph
							body={
								<p>
									The task was clear - design a safe, reliable
									carpooling solution for Pakistan.
								</p>
							}
						/>,
					]}
				/>

				<ProjectBody
					title="Solution"
					children={[
						<Paragraph
							body={
								<p>
									Following the research phase, it was clear
									that the BlaBlaCar app was hitting all the
									right notes. So, why not learn from the
									best? I dove into the app, testing every
									feature, understanding its user flow, and
									even sketching out some wireframes for my
									own app. I didn't want to reinvent the wheel
									- just make it roll better on our local
									terrain.
								</p>
							}
						/>,
						<Paragraph
							body={
								<p>
									In parallel, I began shaping an extensive
									mood board. Visual inspiration? Check. Now,
									armed with a deep understanding of the app's
									flow and information architecture, I started
									sketching out some design drafts.
								</p>
							}
						/>,
						<ProjectImage src={Img2} />,
						<Paragraph
							body={
								<p>
									It was finally time to pull users into the
									loop. ? Their feedback became the guiding
									light as I refined and tweaked the app to
									fit the Pakistani user's needs.
								</p>
							}
						/>,
					]}
				/>

				<ProjectBody
					title="Insights"
					children={[
						<Paragraph
							body={
								<p>
									It was time for some color therapy. The
									original blue scheme, borrowed from
									BlaBlaCar, wasn't quite striking the right
									chord - users found it too clinical. So, I
									decided to test the waters with different
									shades and voila, orange was the crowd
									pleaser.
								</p>
							}
						/>,
						<Paragraph
							body={
								<p>
									Next up was tackling the security elephant.
									To ease the safety concerns, it was clear
									that stringent identification checks were
									needed for everyone joining the platform.
									But why stop there? I made sure to integrate
									emergency alerts and options right on the
									homepage during a ride. A quick tap, and law
									enforcement would be notified. This way,
									users would always have a safety net, making
									their ride a little less stressful and a lot
									more secure.
								</p>
							}
						/>,
						<ProjectImage src={Img4} />,
					]}
				/>

				<ProjectBody
					title={"Testing"}
					children={[
						<Paragraph
							body={
								<p>
									Creating an app that caters to everyone,
									from tech-savvy teens to those less
									acquainted with digital screens, posed a
									late-stage challenge. The homepage, brimming
									with options, was a maze for the less
									tech-inclined users who simply wanted to
									post or book a ride.
								</p>
							}
						/>,
						<Paragraph
							body={
								<p>
									After some head-scratching, the solution
									emerged, simple yet effective - a guiding
									screen before landing on the homepage. This
									one change made the app far more accessible,
									allowing everyone to navigate without
									getting lost in the maze of extra features.
								</p>
							}
						/>,
						<ProjectImage src={Img5} />,
					]}
				/>

				<ProjectBody
					title={"Design"}
					children={[
						<Paragraph
							body={
								<p>
									After navigating through research and
									feedback, it’s time to have a look at the
									actual designs that were led by that
									research.
								</p>
							}
						/>,

						<ProjectImage src={Img6} />,
						<ProjectImage src={Img7} />,
						<ProjectImage src={Img8} />,
						<ProjectImage src={Img9} />,
					]}
				/>

				<ProjectBody
					title={"Key Takeaways"}
					children={[
						<Paragraph
							body={
								<p>
									The journey from recognizing Pakistan's
									commute challenges to the successful launch
									of TravelBuddy was packed with learning
									curves and game-changing realizations. Each
									step, whether it was learning from others,
									involving users, or refining designs, led to
									crucial insights.
								</p>
							}
						/>,
						<Paragraph body={<p>Some key takeaways are:</p>} />,
						<UnorderedList
							items={[
								<p>
									<b>Understanding Commute Challenges</b>: A
									clear understanding of Pakistan's commuting
									issues paved the way for a targeted
									solution.
								</p>,
								<p>
									<b>Learning from Success</b>: Adopting
									successful elements from established models
									expedited the development process and
									enhanced user experience.
								</p>,
								<p>
									<b>User-Centered Approach</b>: Constant
									engagement with users ensured the app met
									usability and functional expectations.
								</p>,
								<p>
									<b>Security Focus</b>: Robust identification
									checks and accessible emergency alerts
									addressed prevalent safety concerns.
								</p>,
								<p>
									<b>Simplicity & Accessibility</b>: An
									intuitive guiding screen made the app
									navigable for all users, regardless of their
									tech proficiency.
								</p>,
								<p>
									<b>Adaptive Development</b>: The ability to
									adjust and evolve the app based on user
									feedback led to an optimized, user-tailored
									solution.
								</p>,
							]}
						/>,
					]}
				/>
				<ProjectBody
					children={[
						<Paragraph
							body={
								<p>
									In closing, thank you for taking the time to
									explore the TravelBuddy story. Here's to
									smoother, safer journeys for everyone!
								</p>
							}
						/>,
					]}
				/>
			</div>
		</main>
	);
}
