import React from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";

import "./components.css";

function Skills() {
	const { scrollYProgress } = useViewportScroll();
	const line1ScrollRange = [0, 1.5]; // adjust these values to control the point of scroll where animation begins and ends
	const line2ScrollRange = [0, 1.5]; // adjust these values to control the point of scroll where animation begins and ends

	const line1TranslateX = useTransform(scrollYProgress, line1ScrollRange, [
		"0vw",
		"100vw",
	]);
	const line2TranslateX = useTransform(scrollYProgress, line2ScrollRange, [
		"0vw",
		"-100vw",
	]);

	return (
		<div className="skills-animated">
			<motion.div
				id="line-1"
				className="animated-skills-strip line-1"
				style={{ translateX: line1TranslateX }}
			>
				<h4>UI/UX Design</h4>
				<h4>Wireframing</h4>
				<h4>Branding</h4>
			</motion.div>
			<motion.div
				id="line-2"
				className="animated-skills-strip line-2"
				style={{ translateX: line2TranslateX }}
			>
				<h4>Digital Design</h4>
				<h4>Visual Design</h4>
				<h4>No Code</h4>
			</motion.div>
		</div>
	);
}

export default Skills;
