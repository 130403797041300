import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./animations.css";

const variants = {
	hidden: { opacity: 0, y: 50 },
	visible: { opacity: 1, y: 0 },
	exit: { opacity: 0, y: 50, transition: { duration: 1 } },
};

const SnackBar = ({ message, duration = 3000, onDismiss }) => {
	useEffect(() => {
		const timer = setTimeout(onDismiss, duration);
		return () => clearTimeout(timer);
	}, [onDismiss, duration]);

	return (
		<div
			style={{
				width: "fit-content",
			}}
		>
			<AnimatePresence>
				{message && (
					<motion.div
						className="notification-container"
						variants={variants}
						initial="hidden"
						animate="visible"
						exit="exit"
					>
						<div className="notification-inner-container">
							{message}
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default SnackBar;
