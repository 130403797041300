import React from "react";
import PropTypes from "prop-types";

const Space = ({ direction, gap, justifyContent, children, className }) => {
	const childrenWithSpaces = React.Children.toArray(children).map(
		(child, index, array) => {
			const isLastChild = index === array.length - 1;
			const marginProp =
				direction === "row" ? "marginRight" : "marginBottom";
			const childStyle = isLastChild ? {} : { [marginProp]: gap };

			return React.cloneElement(child, {
				style: {
					...child.props.style,
					...childStyle,
				},
			});
		}
	);

	const containerStyle = {
		display: "flex",
		flexDirection: direction,
		justifyContent: justifyContent,
	};

	return (
		<div className={className} style={containerStyle}>
			{childrenWithSpaces}
		</div>
	);
};

Space.propTypes = {
	direction: PropTypes.oneOf(["row", "column"]),
	gap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	justifyContent: PropTypes.oneOf([
		"flex-start",
		"flex-end",
		"center",
		"space-between",
		"space-around",
	]),
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

Space.defaultProps = {
	direction: "row",
	gap: "16px",
	justifyContent: "center",
};

export default Space;
