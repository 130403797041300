import React from "react";
import { motion } from "framer-motion";

import Img1 from "../assets/images/test-1.JPG";
import Img2 from "../assets/images/test-2.JPG";

import TravelBuddyCover from "../assets/images/travelbuddy-cover.png";
import TravelBuddyMask from "../assets/images/travelbuddy-mask.png";

import "./styles.css";
import WorkCard from "../components/WorkCard";

export default function Work() {
	const variants = {
		hidden: { opacity: 0, y: 100 },
		visible: { opacity: 1, y: 0 },
	};

	return (
		<main>
			<div className="featured-work full-page">
				<motion.h2
					className="black-text"
					initial="hidden"
					animate="visible"
					variants={variants}
					transition={{ duration: 0.6 }}
				>
					Featured Work
				</motion.h2>

				<WorkCard
					imgSrc={TravelBuddyMask}
					imgSrc2={TravelBuddyCover}
					tag="Product Design"
					title="TravelBuddy"
					subtitle="Car Pooling Mobile App"
					goTo={"travelbuddy"}
					white
				/>

				{/* <WorkCard
					imgSrc={Img2}
					imgSrc2={Img1}
					tag="Product Design"
					title="TravelBuddy"
					subtitle="Car Pooling Mobile App"
					white
				/>

				<WorkCard
					imgSrc={Img2}
					imgSrc2={Img1}
					tag="UI/UX Design"
					title="MEDTRACK"
					subtitle="Inventory Tracking & Management"
					left
					white
				/>

				<WorkCard
					imgSrc={Img2}
					imgSrc2={Img1}
					tag="Design & Deveopment"
					title="Portfolio"
					subtitle="Writer's Portfolio Website"
					white
				/> */}
			</div>
		</main>
	);
}
