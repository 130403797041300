import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Divider() {
	const initial = { opacity: 0, y: 100 };
	const animate = { opacity: 1, y: 0 };
	const transition = { duration: 0.6 };

	// Define your inView properties here
	const triggerOnce = true;
	const threshold = 0.8;

	const { ref, inView } = useInView({
		triggerOnce: triggerOnce,
		threshold: threshold,
	});

	return (
		<motion.div
			ref={ref}
			initial={initial}
			animate={inView ? animate : initial}
			transition={transition}
			style={{
				width: "100%",
				height: "0.0625rem",
				background: "#E6E6E6",
			}}
			className="divider"
		/>
	);
}

/* export default function Divider() {
	return (
		<div
			style={{
				width: "100%",
				height: "0.0625rem",
				background: "#E6E6E6",
			}}
			className="divider"
		></div>
	);
} */
