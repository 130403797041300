import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import Skills from "../components/Skills";
import CircularText from "../assets/circular-text.svg";
import ProfileImg from "../assets/profile-img.png";
import FancyButton from "../components/FancyButton";
import WorkCard from "../components/WorkCard";

import Img1 from "../assets/images/test-1.JPG";
import Img2 from "../assets/images/test-2.JPG";
import Company1 from "../assets/images/company-1.png";
import Company2 from "../assets/images/company-2.png";
import Company3 from "../assets/images/company-3.png";
import Company4 from "../assets/images/company-4.png";

import TravelBuddyCover from "../assets/images/travelbuddy-cover.png";
import TravelBuddyMask from "../assets/images/travelbuddy-mask.png";

import SmartMoneyCover from "../assets/images/smartmoney-mask.png";
import SmartMoneyMask from "../assets/images/smartmoney-cover.png";

import WindshieldCover from "../assets/images/windshield-cover.png";
import WindshieldMask from "../assets/images/windshield-mask.png";

import "./styles.css";
import "./mobile-styles.css";

import Space from "./../components/Space";

export default function Home() {
	return (
		<main
			style={{
				backgroundColor: "white",
			}}
		>
			<Hero />
			<Skills />
			<AboutSection />
			<FeaturedWork />
			<Testimonials />
		</main>
	);
}

function Hero() {
	const [isPageLoaded, setIsPageLoaded] = useState(false);
	const location = useLocation();

	const variants = {
		hidden: { opacity: 0, y: 100 },
		visible: { opacity: 1, y: 0 },
	};

	useEffect(() => {
		const handleLoad = () => {
			setIsPageLoaded(true);
		};
		window.addEventListener("load", handleLoad);
		return () => window.removeEventListener("load", handleLoad);
	}, []);

	useEffect(() => {
		setIsPageLoaded(false);
		const timeoutId = setTimeout(() => setIsPageLoaded(true), 100);
		return () => clearTimeout(timeoutId);
	}, [location]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="hero">
			<div className="hero-content">
				<motion.h1
					variants={variants}
					initial="hidden"
					animate={isPageLoaded ? "visible" : "hidden"}
					transition={{ ease: "easeOut", duration: 0.4 }}
				>
					Product <br /> Designer
				</motion.h1>
				<motion.p
					className="sm black"
					variants={variants}
					initial="hidden"
					animate={isPageLoaded ? "visible" : "hidden"}
					transition={{ ease: "easeOut", duration: 0.7 }}
				>
					Bringing out the core of user experience by designing
					researched and thoughtful products that{" "}
					<span className="fancy"> resonate.</span>
				</motion.p>
			</div>
		</div>
	);
}

function AboutSection() {
	return (
		<div className="about-me-section">
			<div className="avatar">
				<img className="circular-text" src={CircularText} alt="" />
				<img
					className="profile-image"
					src={ProfileImg}
					alt=""
					style={{
						width: 112,
					}}
				/>
			</div>

			<div className="content">
				<p className="large black">
					A digital product designer with 2 years of experience. My
					daily drive is to understand user-issues, and translating
					these into insights to build user-centric and intuitive
					digital solutions!
				</p>

				<FancyButton label={"About me"} linkTo={"/about"} />
			</div>
		</div>
	);
}

function FeaturedWork() {
	const { ref, inView } = useInView({
		triggerOnce: true, // change to false if you want the animation to trigger again whenever it comes in view
		threshold: 0.1, // adjust this value for when you want to trigger the animation
	});

	const variants = {
		hidden: { opacity: 0, y: 100 },
		visible: { opacity: 1, y: 0 },
	};

	return (
		<div className="featured-work">
			<motion.h2
				ref={ref}
				initial="hidden"
				animate={inView ? "visible" : "hidden"}
				variants={variants}
				transition={{ duration: 0.6 }}
			>
				Featured Work
			</motion.h2>

			<WorkCard
				imgSrc={TravelBuddyCover}
				tag="Product Design"
				title="TravelBuddy"
				subtitle="Car Pooling Mobile App"
				goTo={"travelbuddy"}
			/>

			<WorkCard
				imgSrc={SmartMoneyMask}
				tag="Product Design"
				title="SmartMoney"
				subtitle="Personal Budgeting Mobile App"
				/* goTo={"travelbuddy"} */
				left
			/>

			<WorkCard
				imgSrc={WindshieldCover}
				tag="UI Design"
				title="Windshileld 2020"
				subtitle="Donor Management Tool"
				/* goTo={"travelbuddy"} */
			/>

			<FancyButton label={"More coming soon!"} black />

			{/* <WorkCard
				imgSrc={Img2}
				imgSrc2={Img1}
				tag="UI/UX Design"
				title="MEDTRACK"
				subtitle="Inventory Tracking & Management"
				left
			/>

			<WorkCard
				imgSrc={Img2}
				imgSrc2={Img1}
				tag="Design & Deveopment"
				title="Portfolio"
				subtitle="Writer's Portfolio Website"
			/> */}
		</div>
	);
}

function Testimonials() {
	function TestimonialCard({ text, name, title, img }) {
		const { ref, inView } = useInView({
			threshold: 0.1,
			triggerOnce: true,
		});

		return (
			<motion.div
				className="testimonial-card"
				ref={ref}
				initial={{ opacity: 0, y: 50 }}
				animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 100 }}
				transition={{ ease: "easeOut", duration: 0.6 }}
			>
				<p className="xs white" style={{ width: "100%" }}>
					{text}
				</p>
				<div className="testimonial-footer">
					{img && <img src={img} alt="" />}

					<Space direction="column" gap="0.1rem">
						<p className="name">{name}</p>
						<p className="title">{title}</p>
					</Space>
				</div>
			</motion.div>
		);
	}

	return (
		<div className="testimonial-section">
			<div className="companies">
				<img src={Company1} alt="" />
				<img src={Company2} alt="" />
				<img src={Company3} alt="" />
				<img src={Company4} alt="" />
			</div>

			{/* <TestimonialSlider /> */}

			<div className="testimonial-slider">
				<TestimonialCard
					text="“Working with Munaf was a game-changer for our project. His unique blend of technical expertise and creative insight helped us transform our ideas into a user-friendly and aesthetically pleasing product. His designs not only look great but are intuitive and engaging, enhancing the overall user experience.”"
					name="Denis"
					title="CEO, Avanti"
					/* img={ProfileImg} */
				/>
				{/* <div className="testimonial-buttons">
					<div></div>
					<div></div>
					<div></div>
				</div> */}
			</div>
		</div>
	);
}
