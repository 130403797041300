import React from "react";
import { inView, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import "./about.css";
import Divider from "../components/Divider";

export default function About() {
	function StartingSection() {
		const variants = {
			hidden: { opacity: 0, y: 100 },
			visible: { opacity: 1, y: 0 },
		};

		return (
			<div className="starting-section">
				<motion.h3
					initial="hidden"
					animate="visible"
					variants={variants}
					transition={{ duration: 0.7 }}
					style={{ transformOrigin: "top" }} // this ensures rotation happens from the top
				>
					Hi, I’m Muhammad Munaf, <br /> a creative, a{" "}
					<span className="fancy-text"> designer.</span>
				</motion.h3>
			</div>
		);
	}

	return (
		<main>
			<StartingSection />
			<AboutSection />
			<ExperienceSection />
			{/* <div className="about-section full-page white-bg">
				<h5
					style={{
						color: "black",
					}}
				>
					Experience
				</h5>

				<div className="content">
					<ExperienceItem
						company="NorthStarPPC"
						title="Web Designer & Developer"
						dateStart="Feb 2023"
						dateEnd="Present"
						present
					/>
					<Divider />
					<ExperienceItem
						company="YouPal Group"
						title="UX Designer"
						dateStart="Jan 2023"
						dateEnd="Present"
						present
					/>
					<Divider />
					<ExperienceItem
						company="GrayHat"
						title="UI/UX Designer"
						dateStart="Oct 2022"
						dateEnd="Present"
						present
					/>
					<Divider />
					<ExperienceItem
						company="Immutus"
						title="UX Designer"
						dateStart="Mar 2023"
						dateEnd="July 2023"
					/>
					<Divider />
					<ExperienceItem
						company="Daastan"
						title="UI Designer"
						dateStart="Feb 2022"
						dateEnd="Aug 2022"
					/>
				</div>
			</div> */}
		</main>
	);
}

function AboutSection() {
	// Define your animation properties here
	const initial = { opacity: 0, y: 100 };
	const animate = { opacity: 1, y: 0 };
	const transition = { duration: 0.6 };

	// Define your inView properties here
	const triggerOnce = true;
	const threshold = 0.8;

	const { ref: ref1, inView: inView1 } = useInView({
		triggerOnce: triggerOnce,
		threshold: threshold,
	});
	const { ref: ref2, inView: inView2 } = useInView({
		triggerOnce: triggerOnce,
		threshold: threshold,
	});
	const { ref: ref3, inView: inView3 } = useInView({
		triggerOnce: triggerOnce,
		threshold: threshold,
	});

	return (
		<div className="about-section full-page">
			<div className="about-section-content">
				<motion.h5
					initial={initial}
					animate={inView1 ? animate : initial}
					transition={transition}
					ref={ref1}
				>
					ABOUT
				</motion.h5>

				<div className="content">
					<motion.p
						initial={initial}
						animate={inView1 ? animate : initial}
						transition={transition}
						ref={ref1}
					>
						From an early age, technology got the best of my
						curiosity. Somewhere between exploring the components of
						a computer to designing and building digital products, I
						became an adult.
					</motion.p>

					<motion.p
						initial={initial}
						animate={inView2 ? animate : initial}
						transition={transition}
						ref={ref2}
					>
						With the progression of my career, I've been privileged
						enough to engage with diverse projects, surrounding
						websites, landing pages, mobile and web applications.
					</motion.p>

					<motion.p
						initial={initial}
						animate={inView3 ? animate : initial}
						transition={transition}
						ref={ref3}
					>
						As a product designer, I am committed to creating
						visually appealing, user-centric, and intuitive designs
						that effectively address real-world problems. This
						problem-solving aspect of design is my daily catalyst,
						taking up new challenges and moving towards achieving
						excellence!
					</motion.p>
				</div>
			</div>
		</div>
	);
}

// ExperienceItem is now a standalone component
function ExperienceItem({
	company,
	title,
	dateStart,
	dateEnd,
	present,
	triggerOnce,
	threshold,
	initial,
	animate,
	transition,
}) {
	const { ref, inView } = useInView({
		triggerOnce: triggerOnce,
		threshold: threshold,
	});

	return (
		<motion.div
			ref={ref}
			initial={initial}
			animate={inView ? animate : initial}
			transition={transition}
		>
			<div className="experience-item">
				<div className="item-content">
					<h6>{company}</h6>
					<p>{title}</p>
				</div>
				<p className="date">
					{dateStart} -{" "}
					<span className={`${present ? "fancy-text" : ""}`}>
						{dateEnd}
					</span>
				</p>
			</div>
		</motion.div>
	);
}

function ExperienceSection() {
	// Define your animation properties here
	const initial = { opacity: 0, y: 100 };
	const animate = { opacity: 1, y: 0 };
	const transition = { duration: 0.6 };

	// Define your inView properties here
	const triggerOnce = true;
	const threshold = 0.8;

	// Experience data
	const experienceData = [
		{
			company: "Adler One",
			title: "Product Designer",
			dateStart: "Oct 2023",
			dateEnd: "Present",
			present: true,
		},
		{
			company: "YouPal Group",
			title: "UX Designer",
			dateStart: "Jan 2023",
			dateEnd: "Present",
			present: true,
		},
		{
			company: "NorthStarPPC",
			title: "Web Designer & Developer",
			dateStart: "Feb 2023",
			dateEnd: "Sept 2023",
		},
		{
			company: "GrayHat",
			title: "UI/UX Designer",
			dateStart: "Oct 2022",
			dateEnd: "Present",
			present: true,
		},
		{
			company: "Immutus",
			title: "UX Designer",
			dateStart: "Mar 2023",
			dateEnd: "Jul 2023",
		},
		{
			company: "Outsourcing",
			title: "UI Designer",
			dateStart: "Mar 2022",
			dateEnd: "Jan 2023",
		},
		{
			company: "Daastan",
			title: "UX Intern",
			dateStart: "Mar 2022",
			dateEnd: "Aug 2022",
		},
	];

	return (
		<div className="about-section full-page white-bg">
			<div className="about-section-content">
				<h5
					style={{
						color: "black",
					}}
				>
					Experience
				</h5>

				<div className="content">
					{experienceData.map((item, index) => {
						return (
							<React.Fragment key={index}>
								<ExperienceItem
									company={item.company}
									title={item.title}
									dateStart={item.dateStart}
									dateEnd={item.dateEnd}
									present={item.present}
									triggerOnce={triggerOnce}
									threshold={threshold}
									initial={initial}
									animate={animate}
									transition={transition}
								/>
								{index !== experienceData.length - 1 && (
									<Divider />
								)}
							</React.Fragment>
						);
					})}
				</div>
			</div>
		</div>
	);
}
