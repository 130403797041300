import React, { useEffect, useState, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Space from "../components/Space";

import CrossIcon from "../assets/icons/cross.svg";
import MenuIcon from "../assets/icons/menu.svg";

import "./styles.css";

export default function Header() {
	const [menuOpen, setMenuOpen] = useState(false);
	const [mobileActive, setMobileActive] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();

	const menuRef = useRef();

	const handleClick = (route) => {
		if (route === location.pathname) {
			window.scrollTo({ top: 0, behavior: "smooth" });
		} else {
			navigate(route);
		}
		setMenuOpen(false);
	};

	const handleResize = (e) => {
		if (window.innerWidth < 640) setMobileActive(true);
		else setMobileActive(false);
	};

	useEffect(() => {
		function handleClickOutside(event) {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setMenuOpen(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [menuOpen]);

	useEffect(
		() => {
			if (window.innerWidth < 640) setMobileActive(true);
			else setMobileActive(false);
		},
		[] // eslint-disable-line react-hooks/exhaustive-deps
	);

	window.addEventListener("load", handleResize);
	window.addEventListener("resize", handleResize);

	return (
		<nav>
			<Space className="topbar" justifyContent={"space-between"}>
				<NavLink
					to={"/"}
					onClick={() => handleClick("/")}
					className="logo"
				>
					mmunaf
				</NavLink>

				{(!mobileActive || menuOpen) && (
					<div ref={menuRef}>
						<Space
							className={`${
								mobileActive
									? "nav-items-mobile"
									: "nav-items-desktop"
							}`}
							gap={mobileActive ? "0" : "2.25rem"}
							direction={mobileActive ? "column" : "row"}
						>
							<NavLink
								to={"/work"}
								onClick={() => handleClick("/work")}
								activeClassName="active"
							>
								Work
							</NavLink>
							<NavLink
								to={"/about"}
								onClick={() => handleClick("/about")}
								activeClassName="active"
							>
								About
							</NavLink>
							<a
								href="mailto:munafhassan23@gmail.com"
								className="fancy-email"
							>
								Say hello
							</a>
						</Space>
					</div>
				)}

				{mobileActive && (
					<div
						className="icon"
						onMouseDown={(e) => {
							e.stopPropagation(); // Stop the event from bubbling up to the parent
							setMenuOpen(!menuOpen);
						}}
					>
						<img src={menuOpen ? CrossIcon : MenuIcon} alt="" />
					</div>
				)}
			</Space>
		</nav>
	);
}
