import React from "react";

import "./project-styles.css";

export function ProjectHeader({ title, subheading, client, year, role }) {
	return (
		<div className="project-header">
			<div className="main-content">
				<h2 className="black-text">{title}</h2>
				<p className="subheading black">{subheading}</p>
			</div>
			<div className="side-content">
				{client && (
					<div className="item">
						<p className="label">Client</p>
						<p className="value">{client}</p>
					</div>
				)}

				{role && (
					<div className="item">
						<p className="label">Role</p>
						<p className="value">{role}</p>
					</div>
				)}

				{year && (
					<div className="item">
						<p className="label">Year</p>
						<p className="value">{year}</p>
					</div>
				)}
			</div>
		</div>
	);
}

export function ProjectImage({ src }) {
	return (
		<div className="project-img">
			<img src={src} alt="" />
		</div>
	);
}

export function ProjectBody({ title, children }) {
	return (
		<div className="project-body">
			{title && <h5>{title}</h5>}
			{children}
		</div>
	);
}

export function Paragraph({ body }) {
	return <p>{body}</p>;
}

export function UnorderedList({ items }) {
	return (
		<ul>
			{items.map((item, index) => (
				<li key={index}>{item}</li>
			))}
		</ul>
	);
}

export function OrderedList({ items }) {
	return (
		<ol>
			{items.map((item, index) => (
				<li key={index}>{item}</li>
			))}
		</ol>
	);
}
