import { motion } from "framer-motion";

import LinkedInIcon from "../assets/icons/linkedin.svg";
import BehanceIcon from "../assets/icons/behance.svg";
import MediumIcon from "../assets/icons/medium.svg";

import "./components.css";

function FixedSocials() {
	/* const SocialIcon = ({ iconSource, link }) => {
		return (
			<a href={link} className="social-icon-container">
				<div className="social-icon">
					<img src={iconSource} alt="" />
				</div>
			</a>
		);
	}; */

	const SocialIcon = ({ iconSource, link }) => {
		const icon = {
			hidden: { scale: 1, opacity: 1 },
			visible: { scale: 1.1, opacity: 1 },
		};

		return (
			<motion.a
				href={link}
				className="social-icon-container"
				variants={icon}
				whileHover="visible"
				initial="hidden"
				target="_blank"
			>
				<div className="social-icon">
					<img src={iconSource} alt="" />
				</div>
			</motion.a>
		);
	};

	return (
		<div className="fixed-socials">
			<SocialIcon
				iconSource={LinkedInIcon}
				link={"https://www.linkedin.com/in/mmunaf/"}
			/>
			<SocialIcon
				iconSource={BehanceIcon}
				link={"https://www.behance.net/mmunaf"}
			/>
			{/* <SocialIcon iconSource={MediumIcon} link={"https://www.medium.com/mmunaf"} /> */}
		</div>
	);
}
export default FixedSocials;
