import React, { useState, useEffect, useContext } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./components.css";
import { Link, useNavigate } from "react-router-dom";

import ChevronIcon from "../assets/icons/chevron.svg";
import NotificationContext from "../context/NotificationContext";

export default function WorkCard({
	imgSrc,
	tag,
	title,
	subtitle,
	left,
	white,
	goTo = false,
}) {
	const [coordinates, setCoordinates] = useState({ x: "-50%", y: "-50%" });
	const controls = useAnimation();
	const navigate = useNavigate();
	const showSnackbar = useContext(NotificationContext);

	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1,
		rootMargin: "90% 0px",
	});

	useEffect(() => {
		if (inView) {
			controls.start({
				opacity: 1,
				scale: 1,
				y: 0,
				transition: { duration: 1 },
			});
		} else {
			controls.start({ opacity: 0, scale: 0.5, y: "100vh" });
		}
	}, [controls, inView]);

	const handleMouseMove = (e) => {
		if (e.target.className === "project-image") {
			const rect = e.target.getBoundingClientRect();
			setCoordinates({
				x: `${e.clientX - rect.left}px`,
				y: `${e.clientY - rect.top}px`,
			});
		}
	};

	const handleGlobalMouseMove = (e) => {
		const workCards = document.querySelectorAll(".project-image");
		const isInside = Array.from(workCards).some((workCard) => {
			const rect = workCard.getBoundingClientRect();
			return (
				rect.left <= e.clientX &&
				e.clientX <= rect.right &&
				rect.top <= e.clientY &&
				e.clientY <= rect.bottom
			);
		});

		if (!isInside) {
			setCoordinates({ x: "-50%", y: "-50%" });
		}
	};

	useEffect(() => {
		document.addEventListener("mousemove", handleGlobalMouseMove);
		return () => {
			document.removeEventListener("mousemove", handleGlobalMouseMove);
		};
	}, []);

	return (
		<motion.div
			className={`work-card ${white ? "white" : ""}`}
			animate={controls}
			initial={{ opacity: 0, scale: 0.5, y: "100vh" }}
			ref={ref}
			onMouseMove={handleMouseMove}
			onClick={() => {
				if (goTo) navigate(`/works/${goTo}`);
				else showSnackbar("Casestudy coming soon!");
			}}
		>
			<div style={{ position: "relative", order: left ? "2" : "1" }}>
				<img src={imgSrc} className="project-image" alt="" />
			</div>
			<div
				className="content"
				style={{
					order: left ? "1" : "2",
				}}
			>
				<div className="tag">
					<div className="inner-tag">{tag}</div>
				</div>
				<div className="inner-content">
					<h5>{title}</h5>
					<p>{subtitle}</p>
				</div>
				{goTo && (
					<Link className="link-project" to={`/works/${goTo}`}>
						View Project
						<img
							src={ChevronIcon}
							alt="chevnron-right"
							className="animated-icon"
						/>
					</Link>
				)}
			</div>
		</motion.div>
	);
}

/* import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./components.css";
import { Link, useNavigate } from "react-router-dom";

import ChevronIcon from "../assets/icons/chevron.svg";

export default function WorkCard({
	imgSrc,
	tag,
	title,
	subtitle,
	left,
	white,
	goTo = false,
}) {
	const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
	const [isHovering, setIsHovering] = useState(false);
	const controls = useAnimation();
	const navigate = useNavigate();
	const cardRef = React.useRef();

	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1,
		rootMargin: "90% 0px",
	});

	useEffect(() => {
		if (inView) {
			controls.start({
				opacity: 1,
				scale: 1,
				y: 0,
				transition: { duration: 1 },
			});
		}
		if (!inView) {
			controls.start({ opacity: 0, scale: 0.5, y: "100vh" });
		}
	}, [controls, inView]);

	const handleMouseMove = (e) => {
		const rect = cardRef.current.getBoundingClientRect();
		setCoordinates({
			x: e.clientX - rect.left,
			y: e.clientY - rect.top,
		});
		setIsHovering(true);
	};

	const handleMouseOut = () => {
		setIsHovering(false);
	};

	return (
		<motion.div
			className={`work-card ${white ? "white" : ""}`}
			animate={controls}
			initial={{ opacity: 0, scale: 0.5, y: "100vh" }}
			ref={ref}
			onMouseMove={handleMouseMove}
			onMouseOut={handleMouseOut}
			onClick={() => {
				if (goTo) navigate(`/works/${goTo}`);
			}}
		>
			<div
				ref={cardRef}
				style={{ position: "relative", order: left ? "2" : "1" }}
			>
				<img src={imgSrc} className="project-image" alt="" />
				{isHovering && (
					<div
						style={{
							position: "absolute",
							top: coordinates.y,
							left: coordinates.x,
							transform: "translate(-50%, -50%)",
							pointerEvents: "none",
						}}
					>
						View Project
					</div>
				)}
			</div>
			<div
				className="content"
				style={{
					order: left ? "1" : "2",
				}}
			>
				<div className="tag">
					<div className="inner-tag">{tag}</div>
				</div>
				<div className="inner-content">
					<h5>{title}</h5>
					<p>{subtitle}</p>
				</div>
				{goTo && (
					<Link className="link-project" to={`/works/${goTo}`}>
						View Project
						<img
							src={ChevronIcon}
							alt="chevnron-right"
							className="animated-icon"
						/>
					</Link>
				)}
			</div>
		</motion.div>
	);
} */
