import React, { useState, useEffect, useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Work from "./pages/Work";
import About from "./pages/About";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import AnimatedCursor from "./components/AnimatedCursor";
import FixedSocials from "./components/FixedSocials";
import ScrollToTop from "./components/ScrollToTop";

import "./App.css";
import TravelBuddy from "./pages/projects/TravelBuddy";
import NotificationContext from "./context/NotificationContext";
import SnackBar from "./components/SnackBar";

function App() {
	const [mobileActive, setMobileActive] = useState(false);
	const [message, setMessage] = useState("");

	const showSnackbar = useCallback((message) => {
		setMessage(message);
		setTimeout(() => setMessage(""), 3000);
	}, []);

	const handleResize = (e) => {
		if (window.innerWidth < 640) setMobileActive(true);
		else setMobileActive(false);
	};

	useEffect(() => {
		if (window.innerWidth < 640) setMobileActive(true);
		else setMobileActive(false);
	}, []);

	window.addEventListener("load", handleResize);
	window.addEventListener("resize", handleResize);
	return (
		<>
			<NotificationContext.Provider value={showSnackbar}>
				<Header />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/work" element={<Work />} />
					<Route path="/about" element={<About />} />

					{/* defining project paths */}

					<Route
						path="/works/travelbuddy"
						element={<TravelBuddy />}
					/>
				</Routes>
				<ScrollToTop />
				<Footer />
				<FixedSocials />
				{!mobileActive && <AnimatedCursor />}
				<SnackBar message={message} onDismiss={() => setMessage("")} />
			</NotificationContext.Provider>
		</>
	);
}

export default App;
