import React, { useState, useRef, useCallback, useEffect } from "react";

import "./cursor.css";

function useEventListener(eventName, handler, element = document) {
	const savedHandler = useRef();

	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		const isSupported = element && element.addEventListener;
		if (!isSupported) return;

		const eventListener = (event) => savedHandler.current(event);

		element.addEventListener(eventName, eventListener);

		return () => {
			element.removeEventListener(eventName, eventListener);
		};
	}, [eventName, element]);
}

export default function AnimatedCursor({
	color = "220, 90, 90",
	outerAlpha = 0.4,
	innerSize = 8,
	outerSize = 8,
	outerScale = 5,
	innerScale = 0.7,
}) {
	const cursorOuterRef = useRef();
	const cursorInnerRef = useRef();
	const requestRef = useRef();
	const previousTimeRef = useRef();
	const [coords, setCoords] = useState({ x: 0, y: 0 });
	const [isVisible, setIsVisible] = useState(true);
	const [isActive, setIsActive] = useState(false);
	const [isActiveClickable, setIsActiveClickable] = useState(false);
	const [isHoveringImage, setIsHoveringImage] = useState(false); // New state
	let endX = useRef(0);
	let endY = useRef(0);

	const onMouseMove = useCallback(({ clientX, clientY }) => {
		setCoords({ x: clientX, y: clientY });
		if (cursorInnerRef.current) {
			cursorInnerRef.current.style.top = clientY + "px";
			cursorInnerRef.current.style.left = clientX + "px";
		}
		endX.current = clientX;
		endY.current = clientY;
	}, []);

	const animateOuterCursor = useCallback(
		(time) => {
			if (previousTimeRef.current !== undefined) {
				const newX = coords.x + (endX.current - coords.x) / 8;
				const newY = coords.y + (endY.current - coords.y) / 8;
				setCoords({ x: newX, y: newY });
				if (cursorOuterRef.current) {
					cursorOuterRef.current.style.top = newY + "px";
					cursorOuterRef.current.style.left = newX + "px";
				}
			}
			previousTimeRef.current = time;
			requestRef.current = requestAnimationFrame(animateOuterCursor);
		},
		[coords]
	);

	useEffect(() => {
		requestRef.current = requestAnimationFrame(animateOuterCursor);
		return () => cancelAnimationFrame(requestRef.current);
	}, [animateOuterCursor]);

	const onMouseDown = useCallback(() => setIsActive(true), []);
	const onMouseUp = useCallback(() => setIsActive(false), []);
	const onMouseEnter = useCallback(() => setIsVisible(true), []);
	const onMouseLeave = useCallback(() => setIsVisible(false), []);

	useEventListener("mousemove", onMouseMove, document);
	useEventListener("mousedown", onMouseDown, document);
	useEventListener("mouseup", onMouseUp, document);
	useEventListener("mouseenter", onMouseEnter, document);
	useEventListener("mouseleave", onMouseLeave, document);

	useEffect(() => {
		if (isActive && cursorInnerRef.current && cursorOuterRef.current) {
			cursorInnerRef.current.style.transform = `scale(${innerScale})`;
			cursorOuterRef.current.style.transform = `scale(${outerScale})`;
		} else if (cursorInnerRef.current && cursorOuterRef.current) {
			cursorInnerRef.current.style.transform = "scale(1)";
			cursorOuterRef.current.style.transform = "scale(1)";
		}
	}, [innerScale, outerScale, isActive]);

	useEffect(() => {
		if (
			isActiveClickable &&
			cursorInnerRef.current &&
			cursorOuterRef.current
		) {
			cursorInnerRef.current.style.transform = `scale(${
				innerScale * 1.3
			})`;
			cursorOuterRef.current.style.transform = `scale(${
				outerScale * 1.4
			})`;
		}
	}, [innerScale, outerScale, isActiveClickable]);

	useEffect(() => {
		if (cursorInnerRef.current && cursorOuterRef.current) {
			if (isVisible) {
				cursorInnerRef.current.style.opacity = 1;
				cursorOuterRef.current.style.opacity = 1;
			} else {
				cursorInnerRef.current.style.opacity = 0;
				cursorOuterRef.current.style.opacity = 0;
			}
		}
	}, [isVisible]);

	useEffect(() => {
		const clickables = document.querySelectorAll(
			'a, input[type="submit"], input[type="image"], label[for], select, button, .link'
		);

		const images = document.querySelectorAll(".project-image"); // Select images with the class 'project-image'

		const mouseOver = () => setIsActive(true);
		const click = () => {
			setIsActive(true);
			setIsActiveClickable(false);
		};
		const mouseDown = () => setIsActiveClickable(true);
		const mouseUp = () => setIsActive(true);
		const mouseOut = () => {
			setIsActive(false);
			setIsActiveClickable(false);
		};

		const imageMouseOver = () => {
			setIsHoveringImage(true); // Set isHoveringImage to true when mouse is over image
		};
		const imageMouseOut = () => {
			setIsHoveringImage(false); // Set isHoveringImage to false when mouse leaves image
		};

		clickables.forEach((el) => {
			el.style.cursor = "none";

			el.addEventListener("mouseover", mouseOver);
			el.addEventListener("click", click);
			el.addEventListener("mousedown", mouseDown);
			el.addEventListener("mouseup", mouseUp);
			el.addEventListener("mouseout", mouseOut);
		});

		images.forEach((image) => {
			// Add mouseover and mouseout events to the images
			image.addEventListener("mouseover", imageMouseOver);
			image.addEventListener("mouseout", imageMouseOut);
		});

		return () => {
			clickables.forEach((el) => {
				el.removeEventListener("mouseover", mouseOver);
				el.removeEventListener("click", click);
				el.removeEventListener("mousedown", mouseDown);
				el.removeEventListener("mouseup", mouseUp);
				el.removeEventListener("mouseout", mouseOut);
			});

			images.forEach((image) => {
				image.removeEventListener("mouseover", imageMouseOver);
				image.removeEventListener("mouseout", imageMouseOut);
			});
		};
	}, [isActive]);

	useEffect(() => {
		if (isHoveringImage) {
			cursorInnerRef.current.style.opacity = 0; // Make inner dot disappear
			cursorOuterRef.current.style.transform = "scale(16)"; // Make the outer ring larger
		} else if (!isActive && !isActiveClickable) {
			// Reset styles when not hovering image and not clicking
			cursorInnerRef.current.style.opacity = 1;
			cursorOuterRef.current.style.transform = "scale(1)";
		}
	}, [isHoveringImage, isActive, isActiveClickable]);

	const styles = {
		cursor: {
			zIndex: 999,
			position: "fixed",
			opacity: 1,
			pointerEvents: "none",
			transition:
				"opacity 0.15s ease-in-out, transform 0.15s ease-in-out",
		},
		cursorInner: {
			zIndex: 999,
			position: "fixed",
			borderRadius: "50%",
			width: innerSize,
			height: innerSize,
			pointerEvents: "none",
			backgroundColor: `rgba(${color}, 1)`,
			transition:
				"opacity 0.15s ease-in-out, transform 0.25s ease-in-out",
		},
		cursorOuter: {
			zIndex: 999,
			position: "fixed",
			borderRadius: "50%",
			pointerEvents: "none",
			width: outerSize,
			height: outerSize,
			backgroundColor: `rgba(${color}, ${outerAlpha})`,
			transition:
				"opacity 0.15s ease-in-out, transform 0.15s ease-in-out",
		},
	};

	return (
		<React.Fragment>
			<div ref={cursorOuterRef} style={styles.cursorOuter} />
			<div ref={cursorInnerRef} style={styles.cursorInner} />
		</React.Fragment>
	);
}
